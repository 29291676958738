.popup_con {
  font-family: Pretendard;
  padding: 0 20px 30px;
  font-size: 14px;
  line-height: 160%;
}
.terms_title {
  font-family: Pretendard;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  line-height: 25px;
  font-size: 24px;
  font-weight: 700;
}
.terms_text {
  font-family: Pretendard;
  margin: 20px 0 0px;
  &.bold {
    font-weight: bold;
  }
  &.mt5 {
    margin-top: 5px;
  }
}