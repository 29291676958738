* {
  &::-webkit-scrollbar {
    /* 스크롤바 막대 너비 설정 */
    width: 4px;
    height: 4px;
  }

  /* 스크롤바 막대 설정*/
  &::-webkit-scrollbar-thumb {
    /* 스크롤바 막대 높이 설정 */
    height: 4px;
    background-color: #c3c3c3;
    /* 스크롤바 둥글게 설정 */
    border-radius: 10px;
  }

  /* 스크롤바 뒷 배경 설정*/
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}