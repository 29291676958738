.wrap {
  margin: 0 auto;
}

* {
  box-sizing: border-box;
  word-break: keep-all;
  letter-spacing: -0.02rem;
}

body {
  overflow-x: hidden;
}

input:focus, textarea:focus {
  outline: none;
}

.dp-block {
  display: block;
}
.dp_flex {
  display: flex;
}
.gap10 {
  gap: 10px;
}
.w230 {
  width: 230px;
}
.w140 {
  width: 140px;
}
.w150 {
  width: 150px;
}
.w155 {
  width: 155px;
}
.wp100 {
  width: 100%;
}
.mt5 {
  margin-top: 5px;
}
.lh13 {
  line-height: 1.3;
}