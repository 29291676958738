@keyframes menuBar01 {
  0% {
    transform : translateY(10px) rotate(45deg);
  }
  50% {
    transform : translateY(10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
}
@keyframes menuBar02{
  0% {
    transform : translateY(-10px) rotate(-45deg);
  }
  50% {
    transform : translateY(-10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
}
@keyframes activeMenuBar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(8px) rotate(0);
  }
  100% {
    transform: translateY(8px) rotate(45deg);
  }
}
@keyframes activeMenuBar02{
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 100px;
  z-index: 10;
  height: 100px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 1680px){
  padding: 0px 40px;
}
  @media screen and (max-width: 1299px){
    height: 70px;
  }
  @media screen and (max-width: 380px){
    padding: 0px 25px;
  }
  &.color {
    background-color: #fff;
  }
  .inner {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    @media screen and (max-width: 1680px){
      padding: 0px;
    }
    .logo {
      margin-top: 25px;
      width: 98px;
      height: 70px;
      @media screen and (max-width: 1680px){
        width: 75px;
        height: 54px;
      }
      @media screen and (max-width: 1299px){
        width: 57px;
        height: 42px;
        margin-top: 20px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &.color {
        width: 86px;
        height: 61px;
        margin-top: 20px;
        @media screen and (max-width: 1680px){
          width: 75px;
          height: 54px;
          margin-top: 25px;
        }
        @media screen and (max-width: 1299px){
          width: 57px;
          height: 42px;
          margin-top: 15px;
        }
      }
    }
    .gnb {
      display: flex;
      gap: 75px;
      align-items: center;
      height: 100%;
      .gnb_header {
        position: absolute;
        width: 100%;
        height: 70px;
        background-color: red;
      }
      @media screen and (max-width: 1680px){
        gap: 55px;
      }
      & > li {
        @media screen and (max-width: 1299px){
          display: none;
        }
        a {
          font-family: Pretendard;
          color: #fff;
          font-size: 19px;
          cursor: pointer;
          text-decoration: none;
          font-weight: 500;
          @media screen and (max-width: 1680px){
            font-size: 18px;
          }
          &.color {
            color: #191919;
          }
        }
      }
      .btn_hamburger {
        position: relative;
        width: 25px;
        height: 20px;
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        border: 0;
        background-color: transparent;
        z-index: 9999;
        display: none;
        @media screen and (max-width: 1299px){
          display: inline-block;
        }
        &.active {
          span{
            background-color: #292929;
            &:nth-of-type(1){
              animation : activeMenuBar01 .75s forwards;
            }
            &:nth-of-type(2){
              opacity : 0;
            }
            &:nth-of-type(3){
              animation : activeMenuBar02 .75s forwards;
            }
          }
        }
        &.color {
          span {
            background-color: #292929;
          }
        }
        span{
          display: inline-block;
          transition: all .4s;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #fff;
          &:nth-of-type(1){
            top: 0;
            animation :menuBar01 .75s forwards;
          }
          &:nth-of-type(2){
            top: 9px;
            width: 20px;
            opacity: 1;
          }
          &:nth-of-type(3){
            bottom: 0;
            animation : menuBar02 .75s forwards;
          }
        }
      }
    }
    .nav_bar {
      position: fixed;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 9998;
      width: 0px;
      transition: all 0.5s ease 0s;
      display: block;
      background-color: #f7f7f7;
      background-image: url(../../../public/images/icons/ico_logo_nav.png);
      background-repeat: no-repeat;
      background-size: 57px 40px;
      background-position: left 40px top 15px;
      @media screen and (max-width: 1299px){
        &.active{
          width: 400px;
          @media screen and (max-width: 680px) {
            width: 320px;
          }
          @media screen and (max-width: 400px) {
            width: 100%;
          }
        }
        .nav_menu {
          margin-top: 70px;
          height: calc(100vh - 70px);
          background-color: #fff;
          overflow: auto;
          .list_item{
            border-bottom: 1px solid #f5f5f5;
            height: 65px;
            color: #292929;
            font-family: Pretendard;
            font-weight: 500;
            font-size: 20px;
            cursor: pointer;
            line-height: 65px;
            padding: 0 40px;
            text-decoration: none;
            display: block;
            @media screen and (max-width: 680px) {
              font-size: 18px;
            }
            @media screen and (max-height: 720px) {
              height: 45px;
              line-height: 45px;
              font-size: 17px;
            }
            &.dropdown {
              background-repeat: no-repeat;
              background-image: url('../../../public/images/icons/dropdown.png');
              background-position: right 40px center;
              &.active {
                background-image: url('../../../public/images/icons/dropdown02.png');
                color: #0793fb;
                font-weight: bold;
              }
            }
          }
          .nav_dropdown{
            padding: 0px;
            background-color: #f5f5f5;
            overflow: hidden;
            height: 0px;
            transition: 0.5s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 25px;
            &.active {
             height: 110px;
              @media screen and (max-height: 720px) {
                height: 90px;
              }
            }
            & > li {
              a {
                font-family: Pretendard;
                font-size: 18px;
                font-weight: 500;
                text-decoration: none;
                color: #666666;
                padding: 0px 40px;
                @media screen and (max-width: 680px) {
                  font-size: 16px;
                }
                @media screen and (max-height: 720px) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}