.community_wrap {
  max-width: 1400px;
  margin: 100px auto 0px;
  box-sizing: border-box;
  padding: 80px 0px 130px;
  @media screen and (max-width: 1040px) {
    width: auto;
    padding: 75px 40px 100px;
    &.pb120 {
      padding-bottom: 120px;
      @media screen and (max-width: 680px) {
        padding-bottom: 100px;
      }
    }
  }
  @media screen and (max-width: 680px) {
    padding: 30px 40px 80px;
  }
  form {
    padding: 0px 100px;
    box-sizing: border-box;
    @media screen and (max-width: 1450px) {
      padding: 0px 50px;
    }
    @media screen and (max-width: 1040px) {
      padding: 0px;
    }
  }
}
.community_title {
  font-family: Pretendard;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: -0.01em;
  @media screen and (max-width: 1040px) {
    font-size: 36px;
  }
  @media screen and (max-width: 680px) {
    font-size: 30px;
  }
}
.community_content{
  font-family: Pretendard;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  word-break: keep-all;
  color: #555555;
  margin-bottom: 60px;
  @media screen and (max-width: 1040px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 680px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
.community_btn_wrap {
  margin: 0 auto;
  max-width: 855px;
  height: 60px;
  display: flex;
  gap: 15px;
  @media screen and (max-width: 1040px) {
    gap: 20px;
    height: 55px;
  }
  @media screen and (max-width: 680px) {
    gap: 10px;
    height: 45px;
  }
  button {
    width: 50%;
    border-radius: 6px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 500;
    background-color: #f2f2f2;
    color: #999999;
    @media screen and (max-width: 1040px) {
      font-size: 20px;
    }
    @media screen and (max-width: 680px) {
      font-size: 16px;
    }
    &.active {
      background-color: #385cd7;
      color: #fff;
    }
  }
  a {
    width: 50%;
    border-radius: 6px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 500;
    background-color: #f2f2f2;
    color: #999999;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1040px) {
      font-size: 20px;
    }
    @media screen and (max-width: 680px) {
      font-size: 16px;
    }
    &.active {
      background-color: #385cd7;
      color: #fff;
    }
  }
}
.notice{
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  color: #767676;
  line-height: 1.3;
  margin-bottom: 10px;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
}
.table {
  margin: 60px auto 0px;
  max-width: 1400px;
  border-top: 1px solid #282828;
  box-sizing: border-box;

  @media screen and (max-width: 1040px) {
    width: 100%;
  }
  @media screen and (max-width: 680px) {
    margin: 40px auto 0px;
    border-bottom: none;
  }
  .tr {
    height: 70px;
    display: flex;
    border-bottom: 1px solid #d4d8dc;
    @media screen and (max-width: 1040px) {
      width: 100%;
    }
    @media screen and (max-width: 750px) {
      &.email {
        height: auto;
      }
    }
    @media screen and (max-width: 680px) {
      &.asset_gubun {
        height: auto;
      }
      height: 65px;
    }
    @media screen and (max-width: 680px) {
      display: block;
      height: 110px;
      box-sizing: border-box;
      padding: 20px 0px;
    }
    &.h_auto {
      height: auto;
    }
    .th {
      text-align: center;
      height: 100%;
      width: 230px;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 500;
      color: #282828;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 25px;
      @media screen and (max-width: 1040px) {
        width: 25%;
      }
      @media screen and (max-width: 750px){
        padding: 0px 15px;
        &.email {
          height: auto;
        }
      }
      @media screen and (max-width: 680px) {
        font-size: 16px;
        background-color: transparent;
        height: auto;
        width: auto;
        padding: 0px;
      }
      &.h_auto {
        height: auto;
      }
    }
    .td {
      height: 100%;
      width: calc(100% - 230px);
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 1040px) {
        width: 75%;
      }
      @media screen and (max-width: 750px){
        &.email {
          display: block;
        }
      }
      @media screen and (max-width: 680px) {
        padding: 0px;
        width: 100%;
        height: calc(100% - 30px);
        margin-top: 13px;
        &.asset_gubun {
          height: auto;
          margin-top: 20px;
        }
        &.date {
          gap: 5px;
        }
      }
      input,.select,textarea {
        border: 1px solid #d4d8dc;
        border-radius: 3px;
        height: 40px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 15px;
        font-family: Pretendard;
        &.w150 {
          width: 150px;
        }
        &.w230 {
          width: 230px;
        }
        &.necessity_cost {
          width: 230px;
          @media screen and (max-width: 680px) {
            width: calc(100% - 25px);
          }
        }
        @media screen and (max-width: 750px){
          &.email {
            width: calc(100% - 26px);
            margin-bottom: 10px;
            &:first-of-type{
              margin-right: 10px;
            }
            &:nth-of-type(2) + .select{
              margin-bottom: 0px;
            }
          }
          &.price {
            width: calc(100% - 25px);
          }
        }
        @media screen and (max-width: 680px) {
          padding-left: 10px;
          &.wp100 {
            width: 100%;
          }
          &.check_box{
            height: auto;
            margin: 0px;
          }
        }
      }
      input::placeholder{
        font-family: Pretendard;
        font-size: 15px;
        color: #cccccc;
      }
      .date_wrap {
        display: flex;
        gap: 10px;
        @media screen and (max-width: 680px) {
          gap: 5px;
        }
      }
      .select {
        position: relative;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-image: url(../../public/images/icons/ico-down.png);
        background-position: right 10px center;
        color: #999;
        @media screen and (max-width: 680px) {
          padding-left: 10px;
        }
        @media screen and (max-width: 340px) {
          padding-left: 7px;
          background-position: right 7px center;
        }
        &.active {
          background-image: url(../../public/images/icons/ico-up.png);
        };
      }
      .option_area {
        position: absolute;
        width: 100%;
        max-height: 202px;
        overflow-y: auto;
        left: 0px;
        top: 45px;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #d4d8dc;
        background-color: #fff;
        z-index: 3;
      }
      .option {
        height: 40px;
        padding: 10px 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        &:hover {
         color: #767676;
          background-color: #f5f5f5;
        }
        @media screen and (max-width: 680px) {
          padding-left: 10px;
        }
        @media screen and (max-width: 340px) {
          padding-left: 7px;
        }
      }
      textarea {
        border-radius: 5px;
        height: 276px;
        border: 1px solid #d4d8dc;
        padding: 15px;
        resize: none;
        &::placeholder{
          font-family: Pretendard;
          font-size: 16px;
          color: #cccccc;
        };
      }
      .unit {
        font-family: Pretendard;
        font-size: 16px;
        color: #444;
        &.ml9 {
          margin-left: 9px;
        }
      }
      p {
        font-size: 14px;
        font-family: Pretendard;
        color: #767676;
        font-weight: 400;
        margin-top: 10px;
        line-height: 1.3;
        word-break: keep-all;
        @media screen and (max-width: 680px) {
          margin-top: 15px;
        }
        @media screen and (max-width: 340px){
          span {
            display: block;
          }
        }
      }
      &.dp_block {
        display: block;
      }
    }
  }
  &.mt0 {
    margin-top: 0px;
  }
}
.agreement {
  margin: 60px auto 20px;
  @media screen and (max-width: 680px) {
    margin: 40px auto 15px;
    &.wp100 {
      width: 100%;
    }
  }
  h3 {
    font-family: Pretendard;
    font-weight: bold;
    font-size: 24px;
    @media screen and (max-width: 680px) {
      font-size: 20px;
      line-height: 1.3;
    }
  }
}
.agreement_content {
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d4d8dc;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  @media screen and (max-width: 680px) {
    padding: 15px;
  }
  p {
    font-weight: 400;
    font-family: Pretendard;
    font-size: 16px;
    color: #555555;
    line-height: 1.8;
  }
}
.btn_chk {
  text-align: left;
  display: flex;
  gap: 20px;
  .check_wrap {
    position: relative;
    display: flex;
    align-items: center;
    .check_box {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      opacity: 0;
      position: relative;
      margin-right: 10px;
    }
    .checkmark {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #8a8a8a;
      border-radius: 2px;
      @media screen and (max-width: 680px) {
        width: 17px;
        height: 17px;
      }
      @media screen and (max-width: 400px) {
        top: 55%;
      }
    }

    input[type="radio"]:checked ~ .checkmark:after {
      content: '';
      position: absolute;
      bottom: 0%;
      left: 50%;
      background-image: url(../../public/images/icons/ico-check-agree.png);
      background-repeat: no-repeat;
      width: 13px;
      height: 13px;
      transform: translate(-50%,-25%);
      @media screen and (max-width: 680px) {
        background-image: url(../../public/images/icons/ico-check-agree-sm.png);
        width: 11px;
        height: 11px;
        left: 50%;
        transform: translate(-50%,-20%);
      }
    }

    .agree {
      margin: 0 0 0 10px;
      font-size: 16px;
      font-family: "Pretendard";
      font-weight: 400;
      letter-spacing: -0.015em;
      color: #282828;
      @media screen and (max-width: 680px) {
        margin: 0 0 0 10px;
      }
    }
  }
}
.confirm_btn_area {
  margin-top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 1450px) {
    margin-top: 70px;
  }
  @media screen and (max-width: 1040px) {
    margin-top: 60px;
  }
  a,button {
    width: 130px;
    height: 50px;
    font-family: Pretendard;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    @media screen and (max-width: 680px) {
      width: calc(50% - 5px);
      font-size: 18px;
    }
    &.white {
      border: 1px solid #cfcfcf;
      background-color: #fff;
    }
    &.blue {
      background-color: #385cd7;
      color: #fff;
    }
  }
}
.calculator_area {
  width: 100%;
  margin: 40px 0px 30px;
  border-top: 1px solid #ddd;
  padding: 40px 100px 0px;
  box-sizing: border-box;
  @media screen and (max-width: 1450px) {
    padding: 40px 50px 0px;
  }
  @media screen and (max-width: 1040px) {
    padding: 0px;
    margin: 35px 0px 30px;
  }
  @media screen and (max-width: 680px) {
    margin: 30px 0px 20px;
  }
}
.tax_btn_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 1040px) {
    margin-top: 35px;
  }
  @media screen and (max-width: 680px) {
    margin-top: 30px;
  }
  button {
    width: calc(calc(100% - 10px) / 2);
    height: 50px;
    font-family: Pretendard;
    font-size: 19px;
    font-weight: 500;
    border: 1px solid #ddd;
    color: #999;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    @media screen and (max-width: 680px) {
      height: 40px;
      font-size: 15px;
    }
    &.active {
      border: 2px solid #385cd7;
      background-color: #f8f8f8;
      color: #385cd7;
      font-weight: bold;
    }
  }
}
.limit_date {
  font-size: 16px;
  font-family: Pretendard;
  color: #666;
  font-weight: bold;
  padding: 25px 20px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-bottom: 2px solid #133e6e;
  @media screen and (max-width: 680px) {
    font-size: 15px;
    height: auto;
    line-height: 1.5;
  }
}
.reset_btn_area {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 150px;
    height: 55px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 400;
    color: #191919;
    background-color: #fff;
    border: 1px solid #adadad;
    border-radius: 4px;
    cursor: pointer;
    @media screen and (max-width: 680px) {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }
  }
}
.radioFlex {
  display: flex;
  gap: 20px;
  @media screen and (max-width: 680px){
    gap: 25px;
  }
  [type="radio"] {
    vertical-align: middle;
    appearance: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .radioBox {
    position: relative;
    padding: 0 !important;
    margin: 0;
    display: flex;
    align-items: center;
    .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #bababa;
      border-radius: 50%;
      @media screen and (max-width: 400px) {
        top: 55%;
      }
    }
    input:checked ~ .checkmark {
      background: #385cd7;
      border: 1px solid #385cd7;
    }

    input:checked ~ .checkmark:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
    }
    .asset_label {
      display: inline-block;
      font-size: 16px;
      font-family: Pretendard;
      font-weight: 400;
      color: #191919;
      max-width: 100%;
      cursor: pointer;
      @media screen and (max-width: 680px) {
        margin: 0 0 0 10px;
        font-size: 16px;
      }
    }
  }
}
.main {
  box-sizing: border-box;
  word-break: keep-all;
  letter-spacing: -0.02rem;
  height: auto;
  &.ovf_h {
    height: 100%;
    overflow: hidden;
  }
}
.modalWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  .confirmModal {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 55px 0;
    background: #ffffff;
    max-width: 400px;
    height: 300px;
    width: 100%;
    border-radius: 15px;
    text-align: center;
    @media screen and (max-width: 470px) {
      width: 85%;
      padding: 50px 0;
      height: auto;
    }

    .modalClose {
      position: absolute;
      right: 20px;
      top: 25px;
      cursor: pointer;
      @media screen and (max-width: 470px) {
        top: 22px;
      }
    }

    .modalTitle {
      font-family: Pretendard;
      font-size: 28px;
      font-weight: bold;
      @media screen and (max-width: 470px) {
        font-size: 20px;
      }
    }

    .modalContent {
      margin: 15px 0 30px;
      @media screen and (max-width: 470px) {
        margin: 10px 0 15px;
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        font-family: Pretendard;
        letter-spacing: -0.025em;
        line-height: 1.6;
        word-break: keep-all;
      }
    }
    .confirmBtn {
      border: none;
      box-shadow: none;
      border-radius: 10px;
      padding: 0;
      overflow: visible;
      cursor: pointer;
      width: 150px;
      height: 45px;
      color: #fff;
      background-color: #385cd7;
      font-family: Pretendard;
      font-size: 16px;
      @media screen and (max-width: 470px) {
        height: 40px;
      }
    }
  }
}