.overflow-hidden {
  overflow-x: hidden;
}
.main01{
  height: calc(100vh - 70px);
  position: relative;
  @media screen and (max-width: 680px) {
    height: 100vh;
  }
  @media screen and (max-height: 500px){
    height: 100vh;
  }
  .scroll_text {
    display: flex;
    align-items: center;
    position: absolute;
    color: #fff;
    bottom: 90px;
    right: 90px;
    transform: rotate(90deg);
    @media screen and (max-width: 1680px) {
      right: -45px;
    }
    @media screen and (max-width: 680px) {
      display: none;
    }
    span {
      letter-spacing: 0.2rem;
      font-size: 15px;
      font-family: Pretendard;
      font-weight: lighter;
    }
    &:after {
      content: '';
      margin-left: 20px;
      width: 55px;
      height: 1px;
      background-color: #fff;
    }
  }
}
.main01_wrap {
  //max-width: 625px;
  position: absolute;
  left: 260px;
  top: calc(50% + 40px);
  transform: translateY(-50%);
  @media screen and (max-height: 500px) {
    top: 50%;
  }
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 1680px) {
    top: calc(50% + 30px);
    left: 100px;
  }
  @media screen and (max-width: 1080px) {
    left: 40px;
  }
  @media screen and (max-width: 680px) {
    max-width: 400px;
  }
  @media screen and (max-width: 480px) {
    max-width: 250px;
  }
  @media screen and (max-width: 380px) {
    left: 25px;
  }
  @media screen and (max-height: 500px) {
    max-width: none;
  }
  h2 {
    font-family: Pretendard;
    color: #fff;
    &.main01_title {
      font-size: 66px;
      font-weight: 800;
      margin-bottom: 25px;
      letter-spacing: -0.1rem;
      @media screen and (max-width: 1040px) {
        font-size: 54px;
      }
      @media screen and (max-width: 680px) {
        font-size: 42px;
        line-height: 1.2;
      }
      @media screen and (max-height: 500px) {
        font-size: 36px;
        margin-bottom: 5px;
      }
      span {
        &.ls_kr {
          font-size: 62px;
          letter-spacing: -0.15rem;
          @media screen and (max-width: 1040px) {
            font-size: 50px;
          }
          @media screen and (max-width: 680px) {
            font-size: 38px;
          }
          @media screen and (max-height: 500px) {
            font-size: 33px;
          }
        }
      }
    }
    &.main01_sub_title {
      width: 100%;
      font-size: 24px;
      margin-bottom: 20px;
      color: #efefef;
      @media screen and (max-width: 1040px) {
        font-size: 20px;
      }
      @media screen and (max-width: 680px) {
        font-size: 16px;
      }
      @media screen and (max-height: 500px) {
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
    span {
      display: inline-block;
    }
  }
  p {
    font-family: Pretendard;
    color: #efefef;
    font-size: 18px;
    width: 100%;
    @media screen and (max-width: 1040px) {
      font-size: 16px;
    }
    &.main01_describe {
      margin-bottom: 55px;
      @media screen and (max-height: 500px) {
        margin-bottom: 20px;
      }
      span {
        display: block;
        line-height: 1.5;
        @media screen and (max-width: 680px) {
          display: inline;
        }
        @media screen and (max-height: 500px) {
          font-size: 13px;
        }
      }
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px;
    &.btn_service_rq {
      font-family: Pretendard;
      height: 55px;
      background-color: #0793fb;
      color: #fff;
      font-size: 18px;
      border-radius: 27.5px;
      border: none;
      @media screen and (max-width: 1450px) {
        font-size: 16px;
      }
      @media screen and (max-height: 500px) {
        height: 35px;
        font-size: 15px;
      }
      svg.btn_arrow {
        margin-left: 20px;
      }
    }
  }
  .dots_area {
    width: 60px;
    height: 10px;
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1450px) {
      margin-top: 60px;
    }
    @media screen and (max-height: 500px) {
      font-size: 25px;
      margin-top: 15px;
    }
    .dots {
      width: 8px;
      height: 8px;
      background-color: #999ba8;
      border-radius: 50%;
      @media screen and (max-height: 500px) {
        width: 5px;
        height: 5px;
      }
      &.active{
        background-color: #02ccfe;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.tax_check {
  background-color: #1b4477;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  font-family: Pretendard;
  font-weight: 500;
  @media screen and (max-width: 1040px) {
    font-size: 20px;
  }
  @media screen and (max-width: 680px) {
    height: auto;
    padding: 15px 0px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
  }
  p {
    //max-width: 630px;
    @media screen and (max-width: 680px) {
      //max-width: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;
    }
  }
  span {
    &.mark {
      color: #6bc0ff;
    }
  }
  .btn_round {
    border: 1px solid #fff;
    border-radius: 17px;
    background-color: transparent;
    font-family: Pretendard;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    margin-left: 15px;
    padding: 5px 20px;
    @media screen and (max-width: 680px) {
      font-size: 14px;
      padding: 5px 15px;
      margin: 0;

    }
  }
}
.main02 {
  padding: 125px 40px 140px;
  position: relative;
  font-family: Pretendard;
  width: 100%;
  @media screen and (max-width: 1640px) {
    padding: 115px 40px 130px;
  }
  @media screen and (max-width: 1040px) {
    padding: 80px 40px 100px;
  }
  @media screen and (max-width: 750px) {
    padding: 65px 40px 75px;
  }
  @media screen and (max-width: 380px) {
    padding: 65px 25px 70px;
  }
  &:after{
    position: absolute;
    top: 130px;
    right: 0px;
    content: 'HAN KYUNG.Corp';
    font-size: 110px;
    color: #eeeeee;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    width: 910px;
    z-index: -10;
    @media screen and (max-width: 1640px) {
      font-size: 90px;
      top: 100px;
      width: 580px;
    }
    @media screen and (max-width: 1040px) {
      font-size: 61px;
      top: 55px;
      width: 400px;
    }
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .main02_wrapper {
    box-sizing: border-box;
    h2 {
      margin-left: 215px;
      font-family: Pretendard;
      max-width: 565px;
      @media screen and (max-width: 1640px) {
        margin-left: 0px;
      }
      span {
        display: block;
        &.sub_title {
          font-size: 20px;
          margin-bottom: 30px;
          font-weight: 500;
          color: #191919;
          @media screen and (max-width: 680px) {
            margin-bottom: 20px;
          }
        }
        &.main_title {
          font-size: 50px;
          font-weight: bold;
          line-height: 1.3;
          @media screen and (max-width: 1640px) {
            font-size: 44px;
          }
          @media screen and (max-width: 1040px) {
            font-size: 40px;
          }
          @media screen and (max-width: 680px) {
            font-size: 27px;
          }
          @media screen and (max-width: 340px) {
            font-size: 24px;
          }
        }
      }
    }
    .main02_contents {
      margin-top: 110px;
      display: flex;
      justify-content: center;
      gap: 70px;
      @media screen and (max-width: 1640px) {
        justify-content: space-between;
        margin-top: 60px;
        gap: 50px;
      }
      @media screen and (max-width: 1299px) {
        gap: 25px;
      }
      @media screen and (max-width: 1040px) {
        gap: 0px;
        flex-wrap: wrap;
        max-width: 715px;
        margin: 60px auto 0px;
      }
      @media screen and (max-width: 900px) {
      justify-content: space-between;
    }
      @media screen and (max-width: 750px) {
        justify-content: center;
        margin: 55px auto 0px;
        width: 320px;
      }
      @media screen and (max-width: 399px) {
        width: 100%;
      }
      .content_item {
        width: 336px;
        @media screen and (max-width: 1640px) {
          width: 264px;
        }
        @media screen and (max-width: 1040px) {
          width: 320px;
        }
        @media screen and (max-width: 399px) {
          width: 100%;
        }
        &:nth-of-type(even){
          margin-top: 90px;
          @media screen and (max-width: 1640px) {
            margin-top: 70px
          }
          @media screen and (max-width: 1040px) {
            margin-top: 85px
          }
          @media screen and (max-width: 750px) {
            margin-top: 60px
          }
        }
        &:nth-of-type(1){
          background: no-repeat url('../../public/images/img_service01.png');
          @media screen and (max-width: 1640px) {
            background: no-repeat url('../../public/images/img_service01_1300.png');
          }
          @media screen and (max-width: 1040px) {
            background: no-repeat url('../../public/images/img_service01_800.png');
          }
          @media screen and (max-width: 750px) {
            background: no-repeat url('../../public/images/img_service01_400.png');
          }
          @media screen and (max-width: 399px) {
            background-size: 100% 280px;
          }
          @media screen and (max-width: 340px) {
            background-size: 100% 270px;
          }
        }
        &:nth-of-type(2){
          background: no-repeat url('../../public/images/img_service02.png');
          @media screen and (max-width: 1640px), screen and (max-width: 340px) {
            background: no-repeat url('../../public/images/img_service02_1300.png');
          }
          @media screen and (max-width: 1040px) {
            background: no-repeat url('../../public/images/img_service02_800.png');
          }
          @media screen and (max-width: 750px) {
            background: no-repeat url('../../public/images/img_service02_400.png');
          }
          @media screen and (max-width: 399px) {
            background-size: 100% 280px;
          }
          @media screen and (max-width: 340px) {
            background-size: 100% 270px;
          }
        }
        &:nth-of-type(3){
          background: no-repeat url('../../public/images/img_service03.png');
          @media screen and (max-width: 1640px) {
            background: no-repeat url('../../public/images/img_service03_1300.png');
          }
          @media screen and (max-width: 1040px) {
            background: no-repeat url('../../public/images/img_service03_800.png');
          }
          @media screen and (max-width: 750px) {
            margin-top: 65px;
            background: no-repeat url('../../public/images/img_service03_400.png');
          }
          @media screen and (max-width: 399px) {
            background-size: 100% 280px;
          }
          @media screen and (max-width: 340px) {
            background-size: 100% 270px;
          }
        }
        &:nth-of-type(4){
          background: no-repeat url('../../public/images/img_service04.png');
          @media screen and (max-width: 1640px) {
            background: no-repeat url('../../public/images/img_service04_1300.png');
          }
          @media screen and (max-width: 1040px) {
            background: no-repeat url('../../public/images/img_service04_800.png');
          }
          @media screen and (max-width: 750px) {
            background: no-repeat url('../../public/images/img_service04_400.png');
          }
          @media screen and (max-width: 399px) {
            background-size: 100% 280px;
          }
          @media screen and (max-width: 340px) {
            background-size: 100% 270px;
          }
        }
        .describe {
          width: 100%;
          margin-top: 380px;
          text-align: center;
          font-family: Pretendard;
          @media screen and (max-width: 1640px) {
            margin-top: 295px;
          }
          @media screen and (max-width: 1040px) {
            margin-top: 355px;
          }
          @media screen and (max-width: 680px) {
            margin-top: 350px;
          }
          @media screen and (max-width: 399px) {
            margin-top: 310px;
          }
          @media screen and (max-width: 340px) {
            margin-top: 300px;
          }
          h3 {
            font-size: 28px;
            font-weight: bold;
            color: #191919;
            @media screen and (max-width: 1640px) {
              font-size: 26px;
            }
            @media screen and (max-width: 1040px) {
              font-size: 30px;
            }
            @media screen and (max-width: 680px) {
              font-size: 27px;
            }
          }
          p {
            margin-top: 20px;
            color: #333333;
            font-size: 17px;
            line-height: 1.5;
            word-break: keep-all;
            @media screen and (max-width: 1640px) {
              font-size: 16px;
            }
            @media screen and (max-width: 1040px) {
              font-size: 19px;
              margin-top: 15px;
            }
            @media screen and (max-width: 680px) {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
.main03 {
  padding: 0px 0px 0px 80px;
  font-family: Pretendard;
  @media screen and (max-width: 1700px) {
    padding: 0px 0px 0px 70px;
  }
  @media screen and (max-width: 1040px) {
    padding: 0px 40px;
  }
  @media screen and (max-width: 680px) {
    padding: 0px;
  }
  .main03_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 260px 0px 180px;
    background-color: #164986;
    border-top-left-radius: 130px;
    @media screen and (max-width: 1700px) {
      padding: 0px 130px 0px 90px;
    }
    @media screen and (max-width: 1240px) {
      padding: 0px 80px 0px 50px
    }
    @media screen and (max-width: 1140px) {
      padding: 0px 0px 0px 40px
    }
    @media screen and (max-width: 1040px) {
      padding: 95px;
      flex-direction: column;
    }
    @media screen and (max-width: 680px) {
      border-top-left-radius: 85px;
      padding: 65px 40px 70px;
    }
    @media screen and (max-width: 380px) {
      padding: 65px 25px 70px;
    }
    .main03_content {
      max-width: 675px;
      @media screen and (max-width: 1450px) {
        max-width: 520px;
      }
      h2 {
        &.main03_title {
          width: 100%;
          font-size: 50px;
          color: #fff;
          line-height: 1.3;
          margin-bottom: 60px;
          font-family: Pretendard;
          font-weight: 600;
          @media screen and (max-width: 1450px) {
            font-size: 40px;
            margin-bottom: 30px;
            letter-spacing: -0.08rem;
          }
          @media screen and (max-width: 1040px) {
            font-size: 36px;
            text-align: center;
            margin-bottom: 20px;
            &:after {
              display: block;
              content: '';
              width: 30px;
              border: 1px solid #fff;
              background-color: #fff;
              margin-top: 20px;
              margin-left: 50%;
              transform: translateX(-50%);
              @media screen and (max-width: 680px) {
                margin-top: 15px;
              }
            }
          }
          @media screen and (max-width: 680px) {
            font-size: 28px;
          }
          @media screen and (max-width: 340px) {
            font-size: 25px;
          }
          .mark {
            color: #71d2ff;
            @media screen and (max-width: 680px) {
              display: block;
            }
          }
        }
      }
      p {
        font-family: Pretendard;
        font-weight: 400;
        font-size: 18px;
        color: #dbdbdb;
        word-break: keep-all;
        line-height: 1.6;
        width: 100%;
        letter-spacing: -0.01rem;
        word-break: keep-all;
        @media screen and (max-width: 1450px) {
          font-size: 17px;
          line-height: 1.8;
        }
        @media screen and (max-width: 1040px) {
          text-align: center;
        }
        @media screen and (max-width: 340px) {
          font-size: 15px;
        }
      }
    }
    .calculator {
      padding: 120px 50px 140px;
      width: 460px;
      font-family: Pretendard;
      background-color: #1e569a;
      right: 260px;
      top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      @media screen and (max-width: 1450px) {
        padding: 105px 40px;
        max-width: 355px;
      }
      @media screen and (max-width: 1240px) {
        padding: 105px 30px;
      }
      @media screen and (max-width: 1040px) {
        padding: 60px 50px 80px;
        max-width: none;
        margin-top: 70px;
      }
      @media screen and (max-width: 680px) {
        padding: 55px 25px 75px;
        max-width: 320px;
        margin-top: 45px;
      }
      @media screen and (max-width: 399px) {
        max-width: none;
        width: 100%;
      }
      .calculator_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Pretendard;
        width: 100%;
        gap: 20px;
        padding-top: 55px;
        margin: 0px 0px 50px;
        background-image: url(../../public/images/icons/ico-calculator.png);
        background-repeat: no-repeat;
        background-position: center top;
        @media screen and (max-width: 1450px) {
          padding-top: 45px;
          margin: 0px 0px 50px;
          background-size: 22px 28px;
        }
        @media screen and (max-width: 1040px) {
          padding-top: 60px;
          background-size: 29px 36px;
          margin: 0px 0px 30px;
        }
        br{
          display: none;
          @media screen and (max-width: 370px) {
            display: block;
          }
        }
        h3 {
          color: #fff;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: -0.1rem;
          width: 100%;
          text-align: center;
          @media screen and (max-width: 1450px) {
            font-size: 30px;
          }
          @media screen and (max-width: 1040px) {
            font-size: 34px;
          }
          @media screen and (max-width: 680px) {
            font-size: 27px;
          }
        }
        h4 {
          color: #dbdbdb;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.3;
          @media screen and (min-width: 1920px) {
            letter-spacing: -0.015rem;
          }
          @media screen and (max-width: 1450px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1040px) {
            font-size: 18px;
          }
          @media screen and (max-width: 680px) {
            font-size: 16px;
            text-align: center;
            line-height: 1.5;
          }
          @media screen and (max-width: 420px) {
            font-size: 15px;
          }
        }
      }
      .calculator_list {
        font-family: Pretendard;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        @media screen and (max-width: 1040px) {
          width: 100%;
        }
        li {
          a {
            text-decoration: none;
          }
        }
        button {
          font-family: Pretendard;
          width: 100%;
          height: 75px;
          color: #fff;
          font-size: 24px;
          padding: 0px 25px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          @media screen and (max-width: 1450px) {
            padding: 0px 20px;
            font-size: 20px;
            height: 60px;
          }
          @media screen and (max-width: 1040px) {
            padding: 0px 25px;
            height: 65px;
            font-size: 24px;
          }
          @media screen and (max-width: 680px) {
            font-size: 20px;
            height: 55px;
          }
          @media screen and (max-width: 420px) {
            padding: 0px 20px;
            font-size: 17px;
          }
          div {
            background-image: url('../../public/images/icons/ico-check.png');
            background-repeat: no-repeat;
            background-position: left center;
            font-weight: 600;
            padding-left: 35px;
            @media screen and (max-width: 1450px) {
              padding-left: 30px;
              background-size: 18px 18px;
            }
            @media screen and (max-width: 1040px) {
              padding-left: 35px;
              background-size: 22px 22px;
            }
            @media screen and (max-width: 680px) {
              padding-left: 25px;
              background-size: 15px 15px;
            }
          }
          &.btn_calculator_w{
            background-color: #215391;
            border: 1px solid #fff;
          }
          &.btn_calculator_b{
            background-color: #0793fb;
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.main04 {
  width: 100%;
  input {
    padding: 0px;
  }
  &:before {
    content: '';
    display: block;
    width: 80px;
    height: 80px;
    background-color: #82c14e;
    @media screen and (max-width: 1720px) {
      width: 70px;
      height: 70px;
    }
    @media screen and (max-width: 1040px) {
      display: none;
    }
  }
  .main04_wrap{
    padding: 40px 40px 145px;
    margin: 0 auto;
    display: flex;
    max-width: 1560px;
    justify-content: space-between;
    @media screen and (max-width: 1720px) {
      padding: 65px 40px 145px;
    }
    @media screen and (max-width: 1450px) {
      max-width: 100%;
    }
    @media screen and (max-width: 1040px) {
      padding: 90px 40px 100px;
      flex-direction: column;
    }
    @media screen and (max-width: 680px){
      padding: 70px 40px 100px;
    }
    @media screen and (max-width: 380px){
      padding: 70px 25px 100px;
    }
    .contact_wrap{
      width: 340px;
      font-family: 'Pretendard';
      font-size: 19px;
      @media screen and (max-width: 1040px) {
        width: 100%;
      }
      h3 {
        font-size: 66px;
        font-weight: 700;
        margin-bottom: 60px;
        @media screen and (max-width: 1720px) {
          font-size: 60px;
        }
        @media screen and (max-width: 1040px) {
          margin-bottom: 30px;
          font-size: 48px;
        }
        @media screen and (max-width: 680px){
          margin-bottom: 25px;
          font-size: 35px;
        }
      }
      .division{
        width: 40px;
        border-top: 2px solid #d6d8dd;
        margin-bottom: 60px;
        @media screen and (max-width: 1040px) {
          margin-bottom: 30px;
        }
        @media screen and (max-width: 680px) {
          margin-bottom: 25px;
        }
      }
      p {
        color: #555555;
        margin-bottom: 15px;
        @media screen and (max-width: 1040px) {
          font-size: 16px;
        }
      }
      span {
        &.dp-block{
          display: block;
        }
        &.phone {
          font-size: 34px;
          font-weight: 600;
          margin-bottom: 35px;
          @media screen and (max-width: 1040px) {
            font-size: 24px;
            margin-bottom: 20px;
          }
          @media screen and (max-width: 680px) {
            font-size: 22px;
          }
        }
        &.time {
          font-size: 22px;
          color: #555555;
          @media screen and (max-width: 1040px) {
            font-size: 20px;
          }
          @media screen and (max-width: 680px) {
            font-size: 18px;
          }
        }
      }
    }
    form{
      margin-top: 95px;
      width: calc(100% - 630px);
      @media screen and (max-width: 1560px) {
      width: calc(100% - 470px);
      }
      @media screen and (max-width: 1299px) {
        width: calc(100% - 400px);
      }
      @media screen and (max-width: 1040px){
        margin-top: 80px;
        width: 100%;
      }
      @media screen and (max-width: 680px) {
        margin-top: 60px;
      }
      .input_flex {
        width: 100%;
        display: flex;
        margin-bottom: 50px;
        gap: 60px;
        font-family: Pretendard;
        @media screen and (max-width: 1040px){
          flex-direction: column;
          margin-bottom: 60px;
          gap: 65px;
        }
        @media screen and (max-width: 680px) {
          gap: 45px;
          margin-bottom: 45px;
        }
        &:last-of-type {
          margin-bottom: 0px;
        }
        .input_wrap {
          width: 100%;
          .select {
            position: relative;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-image: url(../../public/images/icons/ico-down.png);
            background-position: right 10px center;
            color: #999;
            padding: 0px;
            &.dropdown {
              background-image: url(../../public/images/icons/dropdown.png);
             &.active {
               background-image: url(../../public/images/icons/dropdown02.png);
             }
            }
            @media screen and (max-width: 340px) {
              padding-left: 7px;
              background-position: right 7px center;
            }
            &.active {
              background-image: url(../../public/images/icons/ico-up.png);
            };
          }
          .option_area {
            position: absolute;
            width: 100%;
            max-height: 202px;
            overflow-y: auto;
            left: 0px;
            top: 115%;
            box-sizing: border-box;
            border-radius: 3px;
            border: 1px solid #d4d8dc;
            background-color: #fff;
            z-index: 3;
          }
          .option {
            height: 40px;
            padding: 10px 15px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            &:hover {
              color: #767676;
              background-color: #f5f5f5;
            }
            @media screen and (max-width: 680px) {
              padding-left: 10px;
            }
            @media screen and (max-width: 340px) {
              padding-left: 7px;
            }
          }
          .detail {
            display: inline-block;
            margin-left: 5px;
            font-weight: 700;
            cursor: pointer;
            letter-spacing: -0.05rem;
            @media screen and (max-width: 399px){
              font-size: 15px;
              letter-spacing: -0.07em;
            }
            @media screen and (max-width: 399px){
              margin-left: 5px;
            }
          }
        }
        label {
          width: 100%;
          display: block;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 15px;
          @media screen and (max-width: 1040px){
            font-size: 22px;
            margin-bottom: 10px;
          }
          @media screen and (max-width: 680px) {
            gap: 45px;
            margin-bottom: 5px;
          }
          &.agree {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #191919;
            font-family: Pretendard;
            margin-bottom: 55px;
            width: auto;
            gap: 0px;
            cursor: pointer;
            span {
              @media screen and (max-width: 399px){
                font-size: 15px;
                letter-spacing: -0.07em;
              }
            }
          }
        }
        input,.select {
          width: 100%;
          height: 60px;
          border: none;
          border-bottom: 2px solid #d5d7dc;
          font-family: Pretendard;
          font-size: 18px;
          @media screen and (max-width: 680px) {
            font-size: 16px;
            height: 50px;
          }
          &::placeholder{
            color: #999999;
          }
          &:focus {
            border-bottom: 2px solid #191919;
          }
          &.chk_agree{
            width: 18px;
            height: 18px;
            font-size: 16px;
            margin: 0px 10px 0px 0px;
            @media screen and (max-width: 340px){
              margin: 0px 5px 0px 0px;
            }
          }
        }
        textarea {
          width: 100%;
          font-family: Pretendard;
          border: 1px solid #d5d7dc;
          height: 200px;
          padding: 20px;
          font-size: 18px;
          resize: none;
          margin-bottom: 15px;
          @media screen and (max-width: 1040px){
            margin-top: 15px;
            font-size: 15px;
          }
          &:focus {
            border: 1px solid #191919;
          }
          &::placeholder{
            color: #999999;
          }
        }
      }
    button{
      height: 50px;
      padding: 0px 60px;
      background-color: #82c14e;
      color: #fff;
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.02rem;
      cursor: pointer;
      @media screen and (max-width: 1040px) {
        width: 185px;
        font-size: 18px;
        padding: 0px;
      }
      @media screen and (max-width: 680px) {
        width: 170px;
      }
      }
    }
  }
}
// custom slider
.custom_slider {
/*  .slick-list {
    height: 100%;
    img {
      width: 100vw;
      height: calc(100vh - 70px);
      object-fit: cover;
    }
  }
  ul {
    width: auto;
    box-sizing: border-box;
    &.slick-dots {
      top: 620px;
      bottom: 240px;
      left: 250px;
      height: auto;
      @media screen and (max-width: 1450px) {
        left: 40px;
      }
      li {
        width: 10px;
        height: 10px;
        margin: 0px 20px 0px 0px;
        &:last-of-type{
          margin: 0px;
        }
        box-sizing: border-box;
        button{
          position: relative;
          width: 10px;
          height: 10px;
        }
        button:hover {
          opacity: 1;
          color: #999ba8;
        }
        button:before{
          width: 10px;
          height: 10px;
          color : #999ba8;
        }
        &.slick-active {
          button:before{
            color : #02ccfe;
          }
        }
      }
    }
  }*/
  width: 100vw;
  .img {
    width: 100vw;
    height: calc(100vh - 70px);
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 680px) {
      height: 100vh;
    }
    @media screen and (max-height: 500px) {
      height: 100vh;
    }
    &.first {
      background-image: url(../../public/images/bg_main01.png);
      @media screen and (max-width: 1450px) {
        background-image: url(../../public/images/bg_main01_1300.png);
      }
      @media screen and (max-width: 1040px) {
        background-image: url(../../public/images/bg_main01_800.png);
      }
      @media screen and (max-width: 480px) {
        background-image: url(../../public/images/bg_main01_400.png);
      }
    }
    &.second {
      background-image: url(../../public/images/bg_main02.png);
      @media screen and (max-width: 1450px) {
        background-image: url(../../public/images/bg_main02_1300.png);
      }
      @media screen and (max-width: 1040px) {
        background-image: url(../../public/images/bg_main02_800.png);
      }
      @media screen and (max-width: 480px) {
        background-image: url(../../public/images/bg_main02_400.png);
      }
    }
    &.third {
      background-image: url(../../public/images/bg_main03.png);
      @media screen and (max-width: 1450px) {
        background-image: url(../../public/images/bg_main03_1300.png);
      }
      @media screen and (max-width: 1040px) {
        background-image: url(../../public/images/bg_main03_800.png);
      }
      @media screen and (max-width: 480px) {
        background-image: url(../../public/images/bg_main03_400.png);
      }
    }
  }
}

//서브페이지 공통
.common {
  padding: 180px 30px 100px;
  @media screen and (max-width: 1300px) {
    padding: 170px 40px 90px;
  }
  @media screen and (max-width: 900px) {
    padding: 145px 40px 70px;
  }
  @media screen and (max-width: 500px) {
    padding: 130px 40px 50px;
  }
  &.about {
    p {
      @media screen and (max-width: 620px) {
        line-height: 1.4;
      }
    }
  }
  &.giftTax {
    p {
      .br400 {
        display: none;
        @media screen and (max-width: 460px) {
          display: block;
        }
        @media screen and (max-width: 360px) {
          display: none;
        }
      }
    }
  }
  &.gains {
    p {
      .br400 {
        display: none;
        @media screen and (max-width: 460px) {
          display: block;
        }
        @media screen and (max-width: 360px) {
          display: none;
        }
      }
    }
  }
  &.sources {
    p {
      .br480 {
        display: none;
        @media screen and (max-width: 480px) {
          display: block;
        }
        @media screen and (max-width: 330px) {
          display: none;
        }
      }
    }
  }
  h2 {
    margin: 0 0 20px 0;
    font-size: 45px;
    font-weight: 700;
    font-family: "Pretendard";
    letter-spacing: -0.01em;
    text-align: center;
    color: #191919;
    @media screen and (max-width: 900px) {
      margin: 0 0 15px 0;
      font-size: 36px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 10px 0;
      font-size: 30px;
    }
  }
  p {
    margin: 0 0 47px 0;
    font-size: 20px;
    font-weight: 400;
    font-family: "Pretendard";
    text-align: center;
    color: #555555;
    @media screen and (max-width: 900px) {
      margin: 0 0 35px 0;
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      line-height: 1.4;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 25px 0;
      font-size: 16px;
    }
    .br400 {
      display: none;
      @media screen and (max-width: 550px) {
        display: block;
      }
    }
  }
  figure {
    text-align: center;
    .pc{
      @media screen and (max-width: 1300px) {
        display: none;
      }
    }
    .w1300{
      display: none;
      @media screen and (max-width: 1300px) {
        display: inline;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    .w800{
      display: none;
      @media screen and (max-width: 800px) {
        display: inline;
      }
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    .w400{
      display: none;
      @media screen and (max-width: 400px) {
        display: inline;
      }
    }
  }
}

// 기장서비스 > 고객과의 소통
.introduction {
  padding: 0 30px 150px;
  @media screen and (max-width: 1300px) {
    padding: 0 40px 140px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 40px 100px;
  }
  .story {
    margin: 0 0 90px 0;
    text-align: center;
    @media screen and (max-width: 900px) {
      margin: 0 0 75px 0;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 55px 0;
    }
    h2 {
      margin: 0 0 20px 0;
      font-size: 32px;
      font-family: "Pretendard";
      color: #191919;
      letter-spacing: -0.015em;
      line-height: 1.5;
      @media screen and (max-width: 900px) {
        font-size: 26px;
      }
      @media screen and (max-width: 500px) {
        margin: 0;
        font-size: 22px;
      }
      .bold {
        font-weight: 700;
      }
      .br400 {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      .br450 {
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
      .br375 {
        @media screen and (max-width: 375px) {
          display: none;
        }
      }
      &:after {
        content: "";
        display: none;
        margin: 23px auto;
        width: 30px;
        height: 2px;
        background: #cecece;
        @media screen and (max-width: 500px) {
          display: block;
        }
      }
    }
    p {
      margin: 0 0 75px 0;
      font-size: 18px;
      font-weight: 400;
      font-family: "Pretendard";
      letter-spacing: -0.025em;
      color: #333333;
      line-height: 1.4;
      word-break: keep-all;
      @media screen and (max-width: 900px) {
        margin: 0 0 65px 0;
      }
      @media screen and (max-width: 500px) {
        margin: 0 0 55px 0;
        font-size: 16px;
      }
      .pc {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      .w800 {
        display: none;
        @media screen and (max-width: 900px) {
          display: block;
        }
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      .br375 {
        @media screen and (max-width: 375px) {
          display: none;
        }
      }
      br {
        @media screen and (max-width: 375px) {
          display: none;
        }
      }
    }
    figure {
      text-align: center;
      .pc {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      .w400 {
        display: none;
        @media screen and (max-width: 500px) {
          display: block;
          margin: 0 auto;
        }
      }
      &.accountImg {
        @media screen and (max-width: 900px) {
          margin: 0 auto 0;
        }
        .pcAccount {
          @media screen and (max-width: 500px) {
            display: none;
          }
        }
        .w400 {
          display: none;
          @media screen and (max-width: 500px) {
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .workGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0 0 80px 0;
    @media screen and (max-width: 1030px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 900px) {
      margin: 0 0 60px 0;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 40px 0;
    }
    .item {
      padding: 55px 50px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 hsla(0,0%,72%,.15), 0 2px 15px 0 hsla(0,0%,72%,.15);
      @media screen and (max-width: 1400px) {
        padding: 55px 40px;
      }
      @media screen and (max-width: 1088px) {
        padding: 60px 53px;
      }
      @media screen and (max-width: 700px) {
        padding: 50px 35px;
      }
      @media screen and (max-width: 580px) {
        padding: 40px 30px;
      }
      @media screen and (max-width: 500px) {
        padding: 30px 25px;
      }
      @media screen and (max-width: 380px) {
        padding: 25px;
      }
      h3 {
        margin: 0 0 30px 0;
        font-size: 26px;
        font-weight: 700;
        font-family: "Pretendard";
        letter-spacing: -0.01em;
        color: #191919;
        //@media screen and (max-width: 1400px) {
        //  font-size: 26px;
        //}
        //@media screen and (max-width: 1030px) {
        //  font-size: 30px;
        //}
        @media screen and (max-width: 710px) {
          margin: 0 0 20px 0;
          font-size: 20px;
        }
        @media screen and (max-width: 580px) {
          margin: 0 0 15px 0;
        }
        @media screen and (max-width: 380px) {
          font-size: 17px;
        }
      }
      p {
        position: relative;
        padding: 0 0 0 15px;
        font-size: 20px;
        font-weight: 400;
        font-family: "Pretendard";
        letter-spacing: -0.025em;
        color: #333333;
        word-break: keep-all;
        @media screen and (max-width: 1400px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1120px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1030px) {
          font-size: 20px;
        }
        @media screen and (max-width: 710px) {
          font-size: 17px;
        }
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
        @media screen and (max-width: 380px) {
          font-size: 13px;
        }
        span {
          display: inline-block;
          margin: 5px 0 0;
        }
        &.p_pc {
          margin: 5px 0 0;
          @media screen and (max-width: 580px) {
            display: none;
          }
        }
        &.p580 {
          display: none;
          @media screen and (max-width: 580px) {
            display: block;
          }
        }
        &:first-of-type {
          margin: 0 0 15px 0;
          @media screen and (max-width: 580px) {
            margin: 0 0 10px 0;
          }
        }
        &:nth-of-type(3) {
          margin: 0 0 15px 0;
          @media screen and (max-width: 580px) {
            margin: 0 0 10px 0;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          background: #385cd7;
          vertical-align: middle;
        }
      }
      &:nth-of-type(1) {
        background-image: url(../../public/images/icons/sub-ico-service01.png);
        background-repeat: no-repeat;
        background-position: center right 50px;
        @media screen and (max-width: 1400px) {
          background-image: url(../../public/images/icons/sub-ico-service01_1300.png);
          background-position: center right 37px;
        }
        @media screen and (max-width: 1230px) {
          background-position: center right 25px;
        }
        @media screen and (max-width: 1030px) {
          background-position: center right 50px;
        }
        @media screen and (max-width: 900px) {
          background-image: url(../../public/images/icons/sub-ico-service01_800.png);
          background-position: center right 53px;
          background-size: 13%;
        }
        @media screen and (max-width: 710px) {
          background-position: top 45px right 53px;
        }
        @media screen and (max-width: 580px) {
          //background-size: 13%;
          background-position: top 27px right 25px;
        }
        @media screen and (max-width: 380px) {
          background-position: top 20px right 25px;
        }
      }
      &:nth-of-type(2) {
        background-image: url(../../public/images/icons/sub-ico-service02.png);
        background-repeat: no-repeat;
        background-position: center right 50px;
        @media screen and (max-width: 1400px) {
          background-image: url(../../public/images/icons/sub-ico-service02_1300.png);
          background-position: center right 37px;
        }
        @media screen and (max-width: 1230px) {
          background-position: center right 25px;
        }
        @media screen and (max-width: 1030px) {
          background-position: center right 50px;
        }
        @media screen and (max-width: 900px) {
          background-image: url(../../public/images/icons/sub-ico-service02_800.png);
          background-position: center right 53px;
          background-size: 13%;
        }
        @media screen and (max-width: 710px) {
          background-position: top 45px right 53px;
        }
         @media screen and (max-width: 580px) {
           //background-size: 13%;
          background-position: top 27px right 25px;
        }
        @media screen and (max-width: 380px) {
          background-position: top 20px right 25px;
        }
        p {
          &:before {
            top: 20%;
            transform: translateY(0);
            @media screen and (max-width: 580px) {
              top: 17%;
              transform: translateY(0);
            }
          }
        }
      }
      &:nth-of-type(3) {
        background-image: url(../../public/images/icons/sub-ico-service03.png);
        background-repeat: no-repeat;
        background-position: center right 50px;
        @media screen and (max-width: 1400px) {
          background-image: url(../../public/images/icons/sub-ico-service03_1300.png);
          background-position: center right 25px;
        }
        @media screen and (max-width: 1230px) {
          background-position: center right 25px;
        }
        @media screen and (max-width: 1030px) {
          background-position: center right 50px;
        }
        @media screen and (max-width: 900px) {
          background-image: url(../../public/images/icons/sub-ico-service03_800.png);
          background-position: center right 53px;
          background-size: 13%;
        }
        @media screen and (max-width: 710px) {
          background-position: top 45px right 53px;
        }
        @media screen and (max-width: 580px) {
          //background-size: 13%;
          background-position: top 27px right 25px;
        }
        @media screen and (max-width: 380px) {
          background-position: top 20px right 25px;
        }
        p {
          &:before {
            @media screen and (max-width: 580px) {
              top: 17%;
              transform: translateY(0);
            }
          }
        }
      }
      &:nth-of-type(4) {
        background-image: url(../../public/images/icons/sub-ico-service04.png);
        background-repeat: no-repeat;
        background-position: center right 50px;
        @media screen and (max-width: 1400px) {
          background-image: url(../../public/images/icons/sub-ico-service04_1300.png);
          background-position: center right 37px;
        }
        @media screen and (max-width: 1230px) {
          background-position: center right 27px;
        }
        @media screen and (max-width: 1030px) {
          background-position: center right 50px;
        }
        @media screen and (max-width: 900px) {
          background-image: url(../../public/images/icons/sub-ico-service04_800.png);
          background-position: center right 53px;
          background-size: 13%;
        }
        @media screen and (max-width: 710px) {
          background-position: top 30px right 53px;
        }
         @media screen and (max-width: 580px) {
          //background-size: 13%;
          background-position: top 27px right 25px;
        }
        @media screen and (max-width: 380px) {
          background-position: top 20px right 25px;
        }
        p {
          &:before {
            @media screen and (max-width: 580px) {
              top: 17%;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
  .flex {
    gap: 65px;
    margin: 0 0 80px 0;
    @media screen and (max-width: 1190px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 35px;
      row-gap: 45px;
    }
    @media screen and (max-width: 799px) {
      grid-template-columns: 1fr;
      gap: 80px;
    }
    & > li {
      position: relative;
      flex: 1;
      padding: 55px 30px 50px;
      background: #f6f7f9;
      border-radius: 10px;
      @media screen and (max-width: 1400px) {
        padding: 45px 30px 35px;
      }
      @media screen and (max-width: 1190px) {
        flex: initial;
        //max-width: 450px;
        //width: 100%;
      }
      @media screen and (max-width: 1072px) {
        padding: 55px 30px 40px;
        //max-width: 400px;
      }
      @media screen and (max-width: 799px) {
        padding: 55px 40px 40px;
        max-width: initial;
      }
      h3 {
        margin: 0 0 20px 0;
        font-size: 26px;
        font-weight: 700;
        font-family: "Pretendard";
        letter-spacing: -0.01em;
        color: #191919;
        text-align: center;
        @media screen and (max-width: 1400px) {
          font-size: 21px;
        }
        @media screen and (max-width: 800px) {
          font-size: 24px;
        }
      }
      p {
        position: relative;
        padding: 0 0 0 15px;
        font-size: 17px;
        font-weight: 400;
        font-family: "Pretendard";
        letter-spacing: -0.025em;
        color: #555555;
        word-break: keep-all;
        line-height: 1.2;
        @media screen and (max-width: 1400px) {
          font-size: 16px;
        }
        &:first-of-type {
          margin: 0 0 15px 0;
        }
        &:before {
          content: "";
          position: absolute;
          top: 17%;
          left: 0;
          width: 5px;
          height: 5px;
          background: #385cd7;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          margin: 5px 0 0;
        }
        .w650 {
          display: inline;
          @media screen and (max-width: 650px) {
            display: inline-block;
          }
        }
        .br600 {
          @media screen and (max-width: 580px) {
            display: none;
          }
        }
        .br500 {
          display: none;
          @media screen and (max-width: 580px) {
            display: block;
          }
        }
        .pc {
          @media screen and (max-width: 1430px) {
            display: none;
          }
        }
        br {
          @media screen and (max-width: 350px) {
            display: none;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-position: center;
        width: 68px;
        height: 68px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: -12%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 28px;
        @media screen and (max-width: 1400px) {
          right: -15%;
        }
        @media screen and (max-width: 1190px) {
         display: none;
        }
        //@media screen and (max-width: 799px) {
        //  top: initial;
        //  bottom: -22%;
        //  right: initial;
        //  left: 50%;
        //  transform: translate(-50% , 0) ;
        //  width: 34px;
        //  height: 19px;
        //}
      }
      &:nth-of-type(1) {
        p {
          &:before {
            @media screen and (max-width: 799px) {
              top: 30%;
            }
          }
        }
        p:nth-of-type(1) {
          &:before {
            @media screen and (max-width: 1300px) {
              top: 10%;
            }
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 1072px) {
              top: 20%;
            }
            @media screen and (max-width: 950px) {
              top: 19%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 515px) {
              top: 15%;
            }
            @media screen and (max-width: 356px) {
              top: 11%;
            }
          }
        }
        p:nth-of-type(2) {
          &:before {
            @media screen and (max-width: 1262px) {
              top: 10%;
            }
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 950px) {
              top: 19%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 465px) {
              top: 15%;
            }
            @media screen and (max-width: 337px) {
              top: 11%;
            }
          }
        }
        &:before {
          background-image: url(../../public/images/icons/ico-num-01.png);
        }
        &:after {
          background-image: url(../../public/images/icons/ico-arrow.png);
          //@media screen and (max-width: 1072px) {
          //  right: -15%;
          //}
          //@media screen and (max-width: 1000px) {
          //  right: -12%;
          //}
          //@media screen and (max-width: 975px) {
          //  right: -10%;
          //}
          //@media screen and (max-width: 950px) {
          //  right: -27%;
          //}
          //@media screen and (max-width: 850px) {
          //  right: -20%;
          //}
          //@media screen and (max-width: 820px) {
          //  right: -17%;
          //}
          //@media screen and (max-width: 800px) {
          //  right: -15%;
          //}
          //@media screen and (max-width: 799px) {
          //  background-image: url(../../public/images/icons/ico-arrow-down.png);
          //}
        }
      }
      &:nth-of-type(2) {
        p {
          &:before {
            @media screen and (max-width: 799px) {
              top: 30%;
            }
          }
        }
        p:nth-of-type(1) {
          &:before {
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 950px) {
              top: 19%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 476px) {
              top: 15%;
            }
            @media screen and (max-width: 337px) {
              top: 11%;
            }
          }
        }
        p:nth-of-type(2) {
          &:before {
            @media screen and (max-width: 1223px) {
              top: 10%;
            }
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 950px) {
              top: 19%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 455px) {
              top: 15%;
            }
            @media screen and (max-width: 337px) {
              top: 11%;
            }
          }
        }
        &:before {
          background-image: url(../../public/images/icons/ico-num-02.png);
        }
        &:after {
          background-image: url(../../public/images/icons/ico-arrow.png);
          //@media screen and (max-width: 1150px) {
          //  right: -9%;
          //}
          //@media screen and (max-width: 1150px) {
          //  right: -9%;
          //}
          //@media screen and (max-width: 1072px) {
          //  right: -11.5%;
          //}
          //@media screen and (max-width: 1000px) {
          //  right: -8%;
          //}
          //@media screen and (max-width: 975px) {
          //  right: -6.5%;
          //}
          //@media screen and (max-width: 950px) {
          //  right: -22%;
          //}
          //@media screen and (max-width: 850px) {
          //  right: -13%;
          //}
          //@media screen and (max-width: 799px) {
          //  background-image: url(../../public/images/icons/ico-arrow-down.png);
          //}
        }
      }
      &:nth-of-type(3) {
        p {
          &:before {
            @media screen and (max-width: 799px) {
              top: 30%;
            }
          }
        }
        p:nth-of-type(1) {
          &:before {
            @media screen and (max-width: 1298px) {
              top: 10%;
            }
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 1072px) {
              top: 20%;
            }
            @media screen and (max-width: 950px) {
              top: 19%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 521px) {
              top: 15%;
            }
            @media screen and (max-width: 355px) {
              top: 10%;
            }
          }
        }
        p:nth-of-type(2) {
          &:before {
            top: 40%;
            @media screen and (max-width: 1291px) {
              top: 17%;
            }
            @media screen and (max-width: 1190px) {
              top: 30%;
            }
            @media screen and (max-width: 799px) {
              top: 30%;
            }
            @media screen and (max-width: 521px) {
              top: 15%;
            }
            @media screen and (max-width: 355px) {
              top: 10%;
            }
          }
        }
        //@media screen and (max-width: 1190px) {
        //  margin: 0 0 0 60px;
        //}
        //@media screen and (max-width: 1150px) {
        //  margin: 0 0 0 40px;
        //}
        //@media screen and (max-width: 799px) {
        //  margin: 0;
        //}
        &:before {
          background-image: url(../../public/images/icons/ico-num-03.png);
        }
        &:after {
          background-image: url(../../public/images/icons/ico-arrow.png);
          //@media screen and (max-width: 1190px) {
          //  left: -13%;
          //}
          //@media screen and (max-width: 1150px) {
          //  left: -9%;
          //}
          //@media screen and (max-width: 855px) {
          //  left: -13%;
          //}
          //@media screen and (max-width: 799px) {
          //  top: initial;
          //  bottom: -25%;
          //  right: initial;
          //  left: 50%;
          //  transform: translate(-50%, 0);
          //  background-image: url(../../public/images/icons/ico-arrow-down.png);
          //}
        }
      }
      &:nth-of-type(4) {
        p:nth-of-type(1) {
          &:before {
            @media screen and (max-width: 799px) {
              top: 40%;
            }
            @media screen and (max-width: 550px) {
              top: 45%;
            }
            @media screen and (max-width: 346px) {
              top: 20%;
            }
          }
        }
        //@media screen and (max-width: 1190px) {
        //  margin: 0 0 0 60px;
        //}
        //@media screen and (max-width: 1150px) {
        //  margin: 0 0 0 30px;
        //}
        //@media screen and (max-width: 1072px) {
        //  margin: 0 0 0 20px;
        //}
        //@media screen and (max-width: 850px) {
        //  margin: 0 0 0 40px;
        //}
        //@media screen and (max-width: 799px) {
        //  margin: 0;
        //}
        &:before {
          background-image: url(../../public/images/icons/ico-num-04.png);
        }
        &:after {
          //@media screen and (max-width: 1190px) {
          //  background-image: url(../../public/images/icons/ico-arrow.png);
          //  right: 0;
          //  left: -12%;
          //}
          //@media screen and (max-width: 1100px) {
          //  left: -9%;
          //}
          //@media screen and (max-width: 1072px) {
          //  left: -11%;
          //}
          //@media screen and (max-width: 1010px) {
          //  left: -9.5%;
          //}
          // @media screen and (max-width: 1000px) {
          //  left: -8%;
          //}
          //@media screen and (max-width: 975px) {
          //  left: -7%;
          //}
          //@media screen and (max-width: 950px) {
          //  left: -22%;
          //}
          //@media screen and (max-width: 900px) {
          //  left: -17%;
          //}
          //@media screen and (max-width: 855px) {
          //  left: -15%;
          //}
          //@media screen and (max-width: 800px) {
          //  left: -13%;
          //}
          //@media screen and (max-width: 799px) {
          //  display: none;
          //}
        }
        p {
          &:before {
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  figure {
    margin: 0 0 90px 0;
    text-align: center;
    &.giftImg {
      @media screen and (max-width: 800px) {
        margin: 0 0 80px 0;
      }
      @media screen and (max-width: 500px) {
        margin: 0 0 50px 0;
      }
    }
    .pc {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    .w800 {
      display: none;
      @media screen and (max-width: 800px) {
        display: block;
        margin: 0 auto;
      }
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    .w400 {
      display: none;
      @media screen and (max-width: 400px) {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

// 상담 문의하기 버튼
.cuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  &.right {
    @media screen and (max-width: 1000px) {
      padding: 0 0 0 140px;
      justify-content: flex-start;
    }
    @media screen and (max-width: 900px) {
      padding: 0 0 0 90px ;
    }
    @media screen and (max-width: 830px) {
      padding: 0 0 0 70px ;
    }
    @media screen and (max-width: 650px) {
      padding: 0;
      width: 100%;
    }
  }
  button {
    padding: 15px 53px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Pretendard";
    border-left-width: -0.025em;
    color: #ffffff;
    background: #385cd7;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      font-size: 18px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

// 양도소득세
.measures {
  padding: 0 30px 90px;
  @media screen and (max-width: 1300px) {
    padding: 0 40px 95px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 40px 80px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 40px 65px;
  }
  .story {
    margin: 0;
    text-align: center;
    h2 {
      margin: 0 0 20px 0;
      font-size: 32px;
      font-weight: 700;
      font-family: "Pretendard";
      color: #191919;
      letter-spacing: -0.015em;
      line-height: 1.5;
      @media screen and (max-width: 900px) {
        font-size: 26px;
        line-height: 1.4;
      }
      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
      .bold {
        font-weight: 700;
      }
      br {
        @media screen and (max-width: 400px) {
          display: none;
        }
      }
      &:after {
        content: "";
        display: none;
        margin: 23px auto;
        width: 30px;
        height: 2px;
        background: #cecece;
        @media screen and (max-width: 500px) {
          display: block;
        }
      }
    }
    p {
      margin: 0 0 75px 0;
      font-size: 18px;
      font-weight: 400;
      font-family: "Pretendard";
      letter-spacing: -0.025em;
      color: #333333;
      line-height: 1.4;
      word-break: keep-all;
      @media screen and (max-width: 800px) {
        margin: 0 0 60px 0;
      }
      @media screen and (max-width: 500px) {
        margin: 0 0 55px 0;
        font-size: 16px;
      }
      br {
        @media screen and (max-width: 375px) {
          display: none;
        }
      }
    }
  }
}

// 양도소득세
.strategy {
  padding: 95px 30px 90px;
  background: #f7f9fb;
  @media screen and (max-width: 1300px) {
    padding: 95px 40px 90px;
  }
  @media screen and (max-width: 900px) {
    padding: 60px 40px 110px;
  }
  @media screen and (max-width: 500px) {
    padding: 50px 40px 80px;
  }
  h2 {
    margin: 0 0 50px 0;
    font-size: 32px;
    font-weight: 700;
    font-family: "Pretendard";
    color: #191919;
    line-height: 1.5;
    text-align: center;
    @media screen and (max-width: 800px) {
      margin: 0 0 45px 0;
      font-size: 26px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 30px 0;
      font-size: 22px;
    }
    .br360 {
      @media screen and (max-width: 360px) {
        display: none;
      }
    }
  }
  .flex {
    gap: 20px;
    margin: 0 0 80px 0;
    @media screen and (max-width: 1050px) {
      flex-direction: column;
      gap: 30px;
      margin: 0 0 60px 0;
    }
    & > li {
      flex: 1;
      padding: 55px 60px 55px 220px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center left 50px;
      box-shadow: 0 0 15px 0 rgba(23, 58, 114, 0.05), 0 2px 15px 0 rgba(23, 58, 114, 0.05);
      border-radius: 10px;
      @media screen and (max-width: 900px) {
        padding: 45px 55px 45px 220px;
      }
      @media screen and (max-width: 675px) {
        padding: 45px 60px 55px;
      }
      @media screen and (max-width: 580px) {
        padding: 30px 40px;
      }
      @media screen and (max-width: 500px) {
        padding: 30px;
      }
      &:nth-of-type(1) {
        background-image: url(../../public/images/icons/sub-ico-capital01.png);
        @media screen and (max-width: 675px) {
          background-image: none;
          background-position: initial;
        }
        p {
          &:nth-of-type(1) {
            &:before {
              @media screen and (max-width: 1236px) {
                top: 12%;
              }
              @media screen and (max-width: 1054px) {
                top: 9%;
              }
              @media screen and (max-width: 1050px) {
                top: 40%;
              }
              @media screen and (max-width: 873px) {
                top: 17%;
              }
              @media screen and (max-width: 869px) {
                top: 17%;
              }
              @media screen and (max-width: 382px) {
                top: 11%;
              }
            }
          }
          &:nth-of-type(2) {
            &:before {
              @media screen and (max-width: 1214px) {
                top: 12%;
              }
              @media screen and (max-width: 1068px) {
                top: 9%;
              }
              @media screen and (max-width: 1050px) {
                top: 40%;
              }
              @media screen and (max-width: 894px) {
                top: 17%;
              }
              @media screen and (max-width: 373px) {
                top: 11%;
              }
            }
          }
          &:nth-of-type(3) {
            br{
              @media screen and (max-width: 1433px) {
                display: none;
              }
            }
            &:before {
              @media screen and (max-width: 1147px) {
                top: 12%;
              }
              @media screen and (max-width: 1050px) {
                top: 40%;
              }
              @media screen and (max-width: 781px) {
                top: 17%;
              }
              @media screen and (max-width: 675px) {
                top: 40%;
              }
              @media screen and (max-width: 626px) {
                top: 17%;
              }
              @media screen and (max-width: 580px) {
                top: 40%;
              }
              @media screen and (max-width: 563px) {
                top: 17%;
              }
              @media screen and (max-width: 332px) {
                top: 13%;
              }
            }
          }
        }
      }
      &:nth-of-type(2) {
        background-image: url(../../public/images/icons/sub-ico-capital02.png);
        @media screen and (max-width: 675px) {
          background-image: none;
          background-position: initial;
        }
        p {
          &:nth-of-type(1) {
            &:before {
              @media screen and (max-width: 1381px) {
                top: 12%;
              }
              @media screen and (max-width: 1177px) {
                top: 10%;
              }
              @media screen and (max-width: 1054px) {
                top: 9%;
              }
              @media screen and (max-width: 1050px) {
                top: 17%;
              }
              @media screen and (max-width: 869px) {
                top: 17%;
              }
              @media screen and (max-width: 725px) {
                top: 12%;
              }
              @media screen and (max-width: 675px) {
                top: 17%;
              }
              @media screen and (max-width: 511px) {
                top: 12%;
              }
              @media screen and (max-width: 500px) {
                top: 17%;
              }
              @media screen and (max-width: 451px) {
                top: 11%;
              }
              @media screen and (max-width: 340px) {
                top: 9%;
              }
            }
          }
          &:nth-of-type(2) {
            &:before {
              @media screen and (max-width: 1205px) {
                top: 12%;
              }
              @media screen and (max-width: 1050px) {
                top: 40%;
              }
              @media screen and (max-width: 873px) {
                top: 17%;
              }
              @media screen and (max-width: 369px) {
                top: 11%;
              }
              @media screen and (max-width: 360px) {
                top: 13%;
              }
            }
          }
          &:nth-of-type(3) {
            &:before {
              top: 40%;
              @media screen and (max-width: 1296px) {
                top: 17%;
              }
              @media screen and (max-width: 1050px) {
                top: 40%;
              }
              @media screen and (max-width: 407px) {
                top: 17%;
              }
            }
          }
        }
      }
      h3 {
        margin: 0 0 30px 0;
        font-size: 30px;
        font-weight: 700;
        font-family: "Pretendard";
        letter-spacing: -0.01em;
        color: #191919;
        @media screen and (max-width: 800px) {
          margin: 0 0 20px 0;
          font-size: 26px;
        }
        @media screen and (max-width: 675px) {
          text-align: center;
        }
        @media screen and (max-width: 500px) {
          margin: 0 0 15px 0;
          font-size: 21px;
        }
      }
       p {
         position: relative;
         padding: 0 0 0 15px;
         font-size: 18px;
         font-weight: 400;
         font-family: "Pretendard";
         letter-spacing: -0.025em;
         color: #333333;
         line-height: 1.3;
         word-break: keep-all;
         @media screen and (max-width: 600px) {
           font-size: 17px;
         }
         @media screen and (max-width: 500px) {
           font-size: 15px;
         }
         @media screen and (max-width: 360px) {
           font-size: 14px;
         }
         &:before {
           content: "";
           position: absolute;
           top: 17%;
           left: 0;
           width: 5px;
           height: 5px;
           background: #385cd7;
           vertical-align: middle;
           @media screen and (max-width: 1050px) {
             top: 40%;
           }
         }
         &:nth-of-type(1), &:nth-of-type(2) {
           margin: 0 0 15px 0;
         }
         .line {
           display: inline-block;
           margin: 5px 0 0;
         }
         span {
           margin: 5px 0 0;
         }
       }
    }
  }
}

// 자금출처
.strategic {
  padding: 0 30px 130px;
  @media screen and (max-width: 1300px) {
    padding: 0 40px 140px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 40px 100px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 40px 70px;
  }
  h2 {
    margin: 0 0 55px 0;
    font-size: 32px;
    font-family: "Pretendard";
    color: #191919;
    letter-spacing: -0.015em;
    line-height: 1.5;
    text-align: center;
    @media screen and (max-width: 1300px) {
      margin: 0 0 60px 0;
    }
    @media screen and (max-width: 800px) {
      margin: 0 0 50px 0;
      font-size: 26px;
    }
    @media screen and (max-width: 500px) {
      margin: 0 0 35px 0;
      font-size: 22px;
    }
    .bold {
      font-weight: 700;
    }
    .br800 {
      display: none;
      @media screen and (max-width: 915px) {
        display: block;
      }
      @media screen and (max-width: 340px) {
        display: none;
      }
    }
    .br420 {
      @media screen and (max-width: 422px) {
        display: none;
      }
    }
  }
  figure {
    margin: 0;
    text-align: center;
    .pc {
      @media screen and (max-width: 1030px) {
        display: none;
      }
    }
    .w800 {
      display: none;
      @media screen and (max-width: 1030px) {
        display: inline;
      }
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    .w400 {
      display: none;
      @media screen and (max-width: 400px) {
        display: inline;
      }
    }
  }
}

.sourcesTxt {
  padding: 0 30px 140px;
  @media screen and (max-width: 1300px) {
    padding: 0 40px 140px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 40px 110px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 40px 80px;
  }
  .flex {
    gap: 110px;
    align-items: center;
    margin: 0 0 80px 0;
    @media screen and (max-width: 1300px) {
      gap: 100px;
    }
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 0;
    }
    @media screen and (max-width: 800px) {
      margin: 0 0 60px 0;
    }
    @media screen and (max-width: 400px) {
      margin: 0 0 50px 0;
    }
    figure {
      flex: 1;
      @media screen and (max-width: 1000px) {
        margin: 0 0 50px 0;
      }
      @media screen and (max-width: 400px) {
        margin: 0 0 40px 0;
      }
      .pc {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .w800 {
        display: none;
        @media screen and (max-width: 1000px) {
          display: inline;
        }
        @media screen and (max-width: 400px) {
          display: none;
        }
      }
      .w400 {
        display: none;
        @media screen and (max-width: 400px) {
          display: inline;
        }
      }
    }
    .txt {
      flex: 1;
      @media screen and (max-width: 1000px) {
       padding: 0 140px ;
      }
      @media screen and (max-width: 900px) {
       padding: 0 90px ;
      }
      @media screen and (max-width: 830px) {
       padding: 0 70px ;
      }
      @media screen and (max-width: 650px) {
       padding: 0;
      }
      h2 {
        margin: 0 0 40px 0;
        font-size: 32px;
        font-weight: 700;
        font-family: "Pretendard";
        letter-spacing: -0.035em;
        color: #191919;
        line-height: 1.3;
        @media screen and (max-width: 1025px) {
          font-size: 30px;
        }
        @media screen and (max-width: 800px) {
          margin: 0 0 30px 0;
          font-size: 26px;
        }
        @media screen and (max-width: 500px) {
          margin: 0 0 25px 0;
          font-size: 22px;
        }
        br {
          @media screen and (max-width: 367px) {
            display: none;
          }
        }
      }
      p {
        font-size: 18px;
        font-weight: 400;
        font-family: "Pretendard";
        letter-spacing: -0.025em;
        color: #333333;
        line-height: 1.55;
        word-break: keep-all;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
        .br1460 {
          @media screen and (max-width: 1460px) {
            display: none;
          }
          @media screen and (max-width: 1000px) {
            display: block;
          }
          @media screen and (max-width: 845px) {
            display: none;
          }
        }
        .br1370 {
          @media screen and (max-width: 1370px) {
            display: none;
          }
          @media screen and (max-width: 1000px) {
            display: block;
          }
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        .br1013 {
          @media screen and (max-width: 1032px) {
            display: none;
          }
          @media screen and (max-width: 1000px) {
            display: block;
          }
          @media screen and (max-width: 621px) {
            display: none;
          }
        }
      }
    }
  }
}

//회사소개
.greetings {
  padding: 0 30px 110px;
  @media screen and (max-width: 1700px) {
    padding: 0 0 110px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 0 80px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 0 50px;
  }
  .top {
    margin: 0 auto 50px;
    @media screen and (max-width: 1700px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 1300px) {
      margin: 0 auto 35px;
    }
    @media screen and (max-width: 400px) {
      margin: 0 auto 25px;
    }
    h2 {
      font-size: 40px;
      font-weight: 700;
      font-family: "Pretendard";
      letter-spacing: -0.025em;
      color: #282828;
      text-align: center;
      @media screen and (max-width: 800px) {
        font-size: 30px;
      }
      @media screen and (max-width: 400px) {
        font-size: 24px;
      }
      .blue {
        color: #3760d2;
      }
    }
  }
  .background {
    padding: 60px 40px;
    background: #f7f9fb;
    @media screen and (max-width: 1730px) {
      width: 100%;
    }
    @media screen and (max-width: 900px) {
      padding: 50px 40px;
    }
    @media screen and (max-width: 500px) {
      padding: 30px 40px;
    }
    .flex {
      gap:  110px;
      align-items: center;
      .left {
        @media screen and (max-width: 1220px) {
          display: none;
        }
      }
      .res1300 {
        @media screen and (max-width: 1220px) {
          display: flex;
          align-items: center;
          gap: 60px;
        }
        @media screen and (max-width: 650px) {
          flex-direction: column;
        }
        .disapp {
          display: none;
          @media screen and (max-width: 1220px) {
            display: block;
          }
          .pc {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
          .w400 {
            display: none;
            @media screen and (max-width: 500px) {
              display: block;
            }
          }
        }
      }
      .topGreeting {
        h3 {
          margin: 0 0 30px 0;
          font-size: 30px;
          font-weight: 700;
          font-family: "Pretendard";
          letter-spacing: -0.025em;
          word-break: keep-all;
          @media screen and (max-width: 900px) {
            font-size: 27px;
          }
          @media screen and (max-width: 800px) {
            margin: 0 0 25px 0;
            font-size: 22px;
          }
          @media screen and (max-width: 500px) {
            text-align: center;
          }
          @media screen and (max-width: 400px) {
            line-height: 1.3;
          }
          .blue {
            color: #3760d2;
          }
          .w500 {
            display: none;
            @media screen and (max-width: 400px) {
              display: block;
            }
          }
        }
        .pTot {
          p {
            font-size: 16px;
            font-weight: 400;
            font-family: "Pretendard";
            letter-spacing: -0.025em;
            color: #555555;
            &:nth-of-type(1) {
              margin: 0 0 25px 0;
              @media screen and (max-width: 650px) {
                margin: 0 0 20px 0;
              }
              @media screen and (max-width: 394px) {
                line-height: 1.4;
              }
            }
            &:nth-of-type(2) {
              margin: 0 0 25px 0;
              line-height: 1.5;
              @media screen and (max-width: 650px) {
              margin: 0 0 20px 0;
             }
            }
            &:nth-of-type(3) {
              line-height: 1.5;
              @media screen and (max-width: 800px) {
                margin: 0 0 20px 0;
              }
            }
            &:nth-of-type(4) {
              margin: 0 0 25px 0;
              line-height: 1.5;
              @media screen and (max-width: 650px) {
                margin: 0 0 20px 0;
              }
            }
            .br410 {
              @media screen and (max-width: 410px) {
                display: none;
              }
            }
            br {
              @media screen and (max-width: 375px) {
                display: none;
              }
            }
          }
        }
        strong {
          font-size: 18px;
          font-weight: 700;
          font-family: "Pretendard";
          letter-spacing: -0.025em;
          color: #282828;
          line-height: 1.5;
        }
      }
      .line {
        position: relative;
        margin: 33px 0;
        width: 110%;
        height: 2px;
        background: #e4e8f1;
        @media screen and (max-width: 1400px) {
          width: 770px;
        }
        @media screen and (max-width: 1220px) {
          width: 100%;
        }
        @media screen and (max-width: 400px) {
          margin: 25px 0 30px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 2px;
          background: #215786;
          @media screen and (max-width: 400px) {
            width: 30px;
          }
        }
      }
      .career {
        h3 {
          margin: 0 0 20px 0;
          font-size: 20px;
          font-weight: 700;
          font-family: "Pretendard";
          letter-spacing: -0.025em;
          color: #282828;
          @media screen and (max-width: 1220px) {
           display: none;
          }
        }
        .flex {
          @media screen and (max-width: 1220px) {
            gap: 60px;
          }
          @media screen and (max-width: 650px) {
            flex-direction: column;
            align-items: baseline;
            gap: 20px;
          }
        }
        .flex > li {
          p {
            position: relative;
            margin: 0 0 20px 0;
            padding: 0 0 0 15px;
            font-size: 16px;
            font-weight: 400;
            font-family: "Pretendard";
            letter-spacing: -0.025em;
            color: #555555;
            word-break: keep-all;
            @media screen and (max-width: 380px) {
              font-size: 15px;
            }
            &:last-of-type {
              margin: 0;
            }
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 5px;
              height: 5px;
              background: #0793fb;
              vertical-align: middle;
            }
          }
          &:first-of-type {
            p {
              &:nth-of-type(3) {
                @media screen and (max-width: 732px) {
                  line-height: 1.2;
                }
                &:before {
                  @media screen and (max-width: 732px) {
                    top: 25%;
                  }
                  @media screen and (max-width: 650px) {
                    top: 50%;
                  }
                  @media screen and (max-width: 341px) {
                    top: 25%;
                  }
                }
              }
              &:nth-of-type(4) {
                @media screen and (max-width: 775px) {
                  line-height: 1.2;
                }
                &:before {
                  @media screen and (max-width: 775px) {
                    top: 25%;
                  }
                  @media screen and (max-width: 650px) {
                    top: 50%;
                  }
                  @media screen and (max-width: 360px) {
                    top: 25%;
                  }
                }
              }
            }
          }
          &:last-of-type {
            p {
              &:nth-of-type(3) {
                @media screen and (max-width: 669px) {
                  line-height: 1.2;
                }
                @media screen and (max-width: 650px) {
                  line-height: initial;
                }
                &:before {
                  @media screen and (max-width: 669px) {
                    top: 25%;
                  }
                  @media screen and (max-width: 650px) {
                    top: 50%;
                  }
                  @media screen and (max-width: 344px) {
                    top: 25%;
                  }
                }
              }
              &:nth-of-type(4) {
                &:before {
                  @media screen and (max-width: 319px) {
                    top: 25%;
                  }
                }
              }
              &:nth-of-type(5) {
                @media screen and (max-width: 775px) {
                  line-height: 1.2;
                }
                @media screen and (max-width: 650px) {
                  line-height: initial;
                }
                &:before {
                  @media screen and (max-width: 775px) {
                    top: 25%;
                  }
                  @media screen and (max-width: 650px) {
                    top: 50%;
                  }
                  @media screen and (max-width: 417px) {
                    top: 25%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 회사소개 > 지도
.map {
  padding: 0 40px 80px;
  @media screen and (max-width: 1300px) {
    padding: 0 40px 70px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 40px 50px;
  }
  h2 {
    margin: 0 0 50px 0;
    font-size: 38px;
    font-weight: 700;
    font-family: "Pretendard";
    letter-spacing: -0.025em;
    color: #333333;
    text-align: center;
    @media screen and (max-width: 1300px) {
      margin: 0 0 35px 0;
    }
    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
    @media screen and (max-width: 400px) {
      margin: 0 0 25px 0;
      font-size: 24px;
    }
  }
  #map {
    position: relative;
    margin: 0 0 30px 0;
    border-radius: 10px;
    width: 100%;
    max-width: 1400px;
    height: 500px;
    @media screen and (max-width: 1350px) {
      height: 440px;
    }
    @media screen and (max-width: 800px) {
      height: 320px;
    }
    @media screen and (max-width: 400px) {
      margin: 0 0 20px 0;
      height: 200px;
    }
  }
  .customoverlay {
    top: -10%;
    span {
      position: relative;
      display: inline-block;
      padding: 10px 35px;
      font-size: 15px;
      font-weight: 400;
      font-family: "Pretendard";
      letter-spacing: -0.025em;
      background: #ffffff;
      border: 1px solid rgb(118, 129, 168);
      @media screen and (max-width: 400px) {
        padding: 10px 30px;
        font-size: 14px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -22%;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../../public/images/icons/triangle.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 11px;
        height: 9px;
        @media screen and (max-width: 540px) {
          bottom: -25%;
        }
        @media screen and (max-width: 539px) {
          bottom: -22%;
        }
        @media screen and (max-width: 390px) {
          bottom: -25%;
        }
        @media screen and (max-width: 389px) {
          bottom: -22%;
        }
        @media screen and (max-width: 360px) {
          bottom: -25%;
        }
        @media screen and (max-width: 359px) {
          bottom: -22%;
        }
        @media screen and (max-width: 320px) {
          bottom: -25%;
        }
        @media screen and (max-width: 319px) {
          bottom: -22%;
        }
      }
    }
  }
  .locationInfo {
    .detail {
      padding: 50px 55px;
      background: #ffffff;
      box-shadow: 0 0 15px 0 rgba(23, 58, 114, 0.05), 0 2px 15px 0 rgba(23, 58, 114, 0.05);
      @media screen and (max-width: 800px) {
        padding: 40px 45px;
      }
      @media screen and (max-width: 645px) {
        flex-direction: column;
      }
      @media screen and (max-width: 465px) {
        padding: 30px 20px ;
      }
      & > li {
        h3 {
          font-size: 30px;
          font-weight: 700;
          font-family: "Pretendard";
          letter-spacing: -0.025em;
          color: #333333;
          @media screen and (max-width: 1260px) {
            font-size: 28px;
          }
          @media screen and (max-width: 875px) {
            font-size: 24px;
          }
          @media screen and (max-width: 465px) {
            font-size: 20px;
          }
        }
        &.title {
          margin: 0 70px 0 0;
          padding: 0 80px 0 0;
          border-right: 1px solid #eeeeee;
          @media screen and (max-width: 1350px) {
            margin: 0 50px 0 0;
            padding: 0 50px 0 0;
          }
          @media screen and (max-width: 1255px) {
            margin: 0 30px 0 0;
            padding: 0 30px 0 0;
          }
          @media screen and (max-width: 645px) {
            margin: 0 0 30px 0;
            padding: 0 0 30px 0;
            border-right: none;
            border-bottom: 1px solid #eeeeee;
          }
          @media screen and (max-width: 465px) {
            margin: 0 0 20px 0;
            padding: 0 0 20px 0;
          }
        }
        &.right {
          @media screen and (max-width: 1200px) {
            flex-direction: column;
          }
        }
        .address {
          margin: 0 110px 0 0;
          @media screen and (max-width: 1390px) {
            margin: 0 70px 0 0;
          }
          @media screen and (max-width: 1299px) {
            margin: 0 50px 0 0;
          }
          @media screen and (max-width: 1260px) {
            margin: 0 30px 0 0;
          }
          @media screen and (max-width: 1200px) {
            margin: 0 0 25px 0;
          }
          @media screen and (max-width: 465px) {
            margin: 0 0 15px 0;
          }
          .blue {
            font-size: 18px;
            font-weight: 600;
            font-family: "Pretendard";
            letter-spacing: -0.025em;
            color: #3760d2;
            width: 100px;
            @media screen and (max-width: 1275px) {
              width: 90px;
            }
            @media screen and (max-width: 855px) {
              margin: 3px 0 0 0;
            }
            @media screen and (max-width: 850px) {
              width: 60px;
              margin: 3px 20px 0 0;
            }
            .space {
              margin: 0 30px 0 0;
            }
            @media screen and (max-width: 1260px) {
              font-size: 17px;
            }
            @media screen and (max-width: 465px) {
              font-size: 16px;
            }
            @media screen and (max-width: 380px) {
              font-size: 15px;
            }
          }
          .add {
            p {
              font-size: 18px;
              font-weight: 400;
              font-family: "Pretendard";
              letter-spacing: -0.025em;
              color: #333333;
              &:nth-of-type(1) {
                margin: 0 0 15px 0;
                @media screen and (max-width: 855px) {
                  margin: 0 0 3px 0;
                  line-height: 1.4;
                }
              }
              &:nth-of-type(2) {
                line-height: 1.4;
              }
              @media screen and (max-width: 1260px) {
                font-size: 17px;
              }
              @media screen and (max-width: 465px) {
                font-size: 16px;
              }
              @media screen and (max-width: 380px) {
                font-size: 15px;
              }
              br {
                @media screen and (max-width: 800px) {
                  display: none;
                }
              }
            }
          }
        }
        .number {
          & > li {
            margin: 0 0 25px 0;
            @media screen and (max-width: 465px) {
              margin: 0 0 20px 0;
            }
            .blue {
              width: 100px;
              @media screen and (max-width: 1275px) {
                width: 90px;
              }
              @media screen and (max-width: 850px) {
                width: 60px;
                margin: 0 20px 0 0;
              }
            }
            &:nth-of-type(1) {
              .blue {
                @media screen and (max-width: 339px) {
                  margin: 3px 20px 0 0;
                }
              }
              p {
                @media screen and (max-width: 339px) {
                  line-height: 1.3;
                }
                .br339 {
                  display: none;
                  @media screen and (max-width: 339px) {
                    display: block;
                  }
                }
              }
            }
            &:nth-of-type(2) {
              //.blue {
              //  margin: 0 44px 0 0;
              //  @media screen and (max-width: 800px) {
              //    margin: 0 33px 0 0;
              //  }
              //}
              .blue {
                @media screen and (max-width: 375px) {
                  margin: 3px 20px 0 0;
                }
              }
              p {
                @media screen and (max-width: 375px) {
                  line-height: 1.3;
                }
                .br375 {
                  display: none;
                  @media screen and (max-width: 375px) {
                    display: block;
                  }
                }
              }
            }
            &:last-of-type {
              margin: 0;
            }
          }
          .blue {
            font-size: 18px;
            font-weight: 600;
            font-family: "Pretendard";
            letter-spacing: -0.025em;
            color: #3760d2;
            .space {
              margin: 0 30px 0 0;
            }
            .space2 {
              margin: 0 6px;
            }
            .space3 {
              margin: 0 4px;
            }
            @media screen and (max-width: 1260px) {
              font-size: 17px;
            }
            @media screen and (max-width: 465px) {
              font-size: 16px;
            }
            @media screen and (max-width: 380px) {
              font-size: 15px;
            }
          }
          p {
            font-size: 18px;
            font-weight: 400;
            font-family: "Pretendard";
            letter-spacing: -0.025em;
            color: #333333;
            @media screen and (max-width: 1260px) {
              font-size: 17px;
            }
            @media screen and (max-width: 465px) {
              font-size: 16px;
            }
            @media screen and (max-width: 380px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}