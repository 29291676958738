footer {
  padding: 60px 150px 80px;
  background: #efefef;
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 70px;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 17px;
  @media screen and (max-width: 1450px) {
    padding: 60px 40px 80px;
  }
  @media screen and (max-width: 1080px) {
    padding: 40px 40px 55px;
      flex-direction: column;
      gap: 25px;
      font-size: 15px;
      img {
        width: 76px;
        height: 53px;
      }
  }
  @media screen and (max-width: 750px) {
    padding: 40px 40px 70px;
    img {
      width: 86px;
      height: 61px;
    }
  }
  @media screen and (max-width: 380px) {
    padding: 40px 25px 55px;
  }
  &.active {
    background: #ffffff;
  }
  .address {
    margin-bottom: 10px;
    line-height: 1.5;
    br{
      display: none;
    }
    @media screen and (max-width: 450px) {
      margin-bottom: 20px;
      br{
        display: block;
      }
    }
  }
  .contact {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    gap: 55px;
    word-break: keep-all;
    .tel_wrap {
      display: flex;
    }
    .tag {
      font-weight: 600;
      display: inline-block;
      margin-right: 10px;
    }
    span {
      display: block;
      line-height: 1.5;
      word-break: keep-all;
      .second_tel {
        display: block;
/*        margin-left: 45px;
        @media screen and (max-width: 1080px) {
          margin-left: 40px;
        }*/
      }
    }
    @media screen and (max-width: 850px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  .copyright {
    font-family: Pretendard;
    font-weight: 400;
    font-size: 15px;
    color: #767676;
    line-height: 1.6;
    @media screen and (max-width: 1080px) {
      font-size: 14px;
    }
  }
  .top {
    display: block;
    top: 60px;
    right: 95px;
    cursor: pointer;
    position: absolute;
    z-index: 7;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-image: url(../../../public/images/icons/ico-top-arrow.png);
    background-color: #1b4477;
    background-position: center top 15px;
    @media screen and (max-width: 1450px) {
      right: 40px;
    }
    @media screen and (max-width: 1080px) {
      top: 35px;
      width: 51px;
      height: 51px;
      background-position: center top 12px;
    }
    @media screen and (max-width: 750px) {
      top: 40px;
      width: 45px;
      height: 45px;
      background-position: center top 10px;
    }
    @media screen and (max-width: 380px) {
      right: 25px;
    }
    .icon_top {
      font-size: 14px;
      font-weight: 400;
      font-family: Pretendard;
      position: absolute;
      bottom: 17px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      @media screen and (max-width: 1080px) {
        bottom: 13px;
      }
      @media screen and (max-width: 750px) {
        bottom: 10px;
        font-size: 13px;
      }
    }
/*    &.active {
      display: block;
    }*/
  }
}