.w1756 {
    margin: 0 auto;
    /*max-width: 1756px;*/
    /*width: 100%;*/
}

.w1560 {
    margin: 0 auto;
    max-width: 1560px;
    width: 100%;
}
.container {
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
}

.flex {
    display: flex;
}

* {
    outline: 1px solid --lightseagreen;
    box-sizing: border-box;
}