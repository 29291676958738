@font-face {
    font-family: "Pretendard-Thin";
    font-style: normal;
    font-weight: 100;
    src: url('../../public/fonts/Pretendard-Thin.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-ExtraLight";
    font-style: normal;
    font-weight: 200;
    src: url('../../public/fonts/Pretendard-ExtraLight.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-Light";
    font-style: normal;
    font-weight: 300;
    src: url('../../public/fonts/Pretendard-Light.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/Pretendard-Regular.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-Medium";
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/Pretendard-Medium.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    src: url('../../public/fonts/Pretendard-SemiBold.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-Bold";
    font-style: normal;
    font-weight: 700;
    src: url('../../public/fonts/Pretendard-Bold.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-ExtraBold";
    font-style: normal;
    font-weight: 800;
    src: url('../../public/fonts/Pretendard-ExtraBold.woff') format('font-woff');
}

@font-face {
    font-family: "Pretendard-Black";
    font-style: normal;
    font-weight: 900;
    src: url('../../public/fonts/Pretendard-Black.woff') format('font-woff');
}